import {reqGetCode, reqUserRegister, reqUserLogin, reqUserInfo, reqLogout} from "@/api";

const state = {
    code: '',
    token: localStorage.getItem('TOKEN'),
    userInfo: {}
};
const mutations = {
    GETCODE(state, code) {
        state.code = code;
    },
    USERLOGIN(state, token) {
        state.token = token;
    },
    GETUSERINFO(state, userInfo) {
        state.userInfo = userInfo;
    },
    CLEAR(state) {
        state.userInfo = {};
        state.token = '';
        localStorage.removeItem('TOKEN');
    }
};
const actions = {
    async getUserInfo({commit}) {
        let result = await reqUserInfo();
        if(result.code == 200) {
            commit('GETUSERINFO', result.data);
            return 'ok';
        } else {
            return Promise.reject(new Error('fail'));
        }
    },
    async userLogout({commit}) {
        let result = await reqLogout();
        if(result.code == 200) {
            commit('CLEAR')
            return 'ok';
        } else {
            return Promise.reject(new Error('fail'));
        }
    }
};
const getters = {};
export default {
    state,
    mutations,
    actions,
    getters
}
