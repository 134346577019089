import {reqNewsTypeList} from "@/api/News";

const state = {
    newsTypeList: [],
};
const mutations = {
    NEWSTYPELIST(state, newsTypeList) {
        state.newsTypeList = newsTypeList;
    },
};
const actions = {
    async getNewsTypeList({commit}) {
        commit('NEWSTYPELIST', [
            {id: 1, name: '要闻'},
            {id: 2, name: '通知公告'},
            {id: 3, name: '政策规章'},
            {id: 4, name: '经验介绍'},
        ]);
        /*let result = await reqNewsTypeList();
        if(result.code === 200) {
            commit('NEWSTYPELIST', result.data);
        }*/
    },
};
const getters = {};

export default {
    state,
    mutations,
    actions,
    getters
}
