import axios from "axios";
import store from '@/store';
//引入nprogress及其css样式，进度条颜色是可以改的
import nprogress from 'nprogress';
import 'nprogress/nprogress.css';

const requests = axios.create({
   baseURL: '/prod-api',
   timeout: 50000
});

requests.interceptors.request.use((config) => {
    //进度条开始
    nprogress.start();

    // if(store.state.detail.uuid_token) {
    //     config.headers.userTempId = store.state.detail.uuid_token;
    // }

    //需要携带token带给服务器
    if(store.state.user.token) {
        config.headers.token = store.state.user.token;
    }
    //获取token并携带给服务器
    if(localStorage.getItem('userInfo')){
        let userInfo = JSON.parse(localStorage.getItem('userInfo'))
        if(userInfo.token){
            config.headers.token = userInfo.token
        }
    }
    return config;
}, (error) => {
    return Promise.reject(new Error('fail'));
});

requests.interceptors.response.use((res) => {
    //进度条结束
    nprogress.done();
    return res.data;
}, (error) => {
    return Promise.reject(new Error('fail'));
});

export default requests;
