<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {

    }
  },
  components: {},
  mounted() {
    //通知Vuex发送请求，获取数据，存储于仓库当中

  }
}
</script>

<style lang="scss">
	@import url("assets/styles/style.css");
  #nprogress .bar {
    background: #ED2B25 !important;
  }
  body::-webkit-scrollbar,html::-webkit-scrollbar,div::-webkit-scrollbar {
    /*隐藏滚轮*/
    display: none;
  }

  /* 局部样式，定义.scroll-table类名下的滚动条样式 */
  #scroll-table {
    overflow: auto !important;
  }

  #scroll-table::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    display: block !important; /* 使用!important强制应用样式 */
  }

  /* 设置滚动条整体，宽高 */
  #scroll-table::-webkit-scrollbar{
    width:8px;
    height:8px;
  }
  /* 设置滚动条的轨道 */
  #scroll-table::-webkit-scrollbar-track{
    margin-right: 10px;
    background: #d4e1e5;
    border-radius:10px;
  }
  /* 设置滚动条里面的小方块 */
  #scroll-table::-webkit-scrollbar-thumb {
    background: #409EFF;
    border-radius: 20px;
  }
    //::-webkit-scrollbar {
  //  /*隐藏滚轮*/
  //  display: none;
  //}
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button{
    -webkit-appearance: none !important;
    margin: 0;
  }
  input[type="number"]{-moz-appearance:textfield;}
  #app {
    width: 100%;
    font-family: "PingFang SC","Microsoft YaHei",SansSerif;

  }


  /*transition效果*/
  /* 进入动画 */
  .form-enter-active, .form-leave-active {
    transition: opacity .1s;
  }
  /* 离开动画 */
  .form-enter, .form-leave-to {
    opacity: 0;
  }

  .common_radius {
    border-radius: 20px;
    box-shadow: 0 0 7px 5px #f6f5f5;
  }
  .common_ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  a {
    text-decoration: none !important;
  }

  .router-link-active {
    text-decoration: none !important;
  }

  .ql-container.ql-snow {
    border: none !important;
  }

  * {
    text-decoration: none;
  }
</style>
