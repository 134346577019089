<template>
  <!-- 底部 -->
  <div class="crumb-container">
    <el-breadcrumb separator-class="el-icon-arrow-right">
<!--    <el-breadcrumb separator=">>">-->
      <el-breadcrumb-item><a href="/">首页</a></el-breadcrumb-item>
      <el-breadcrumb-item :class="{isCurrentPage: index === crumbList.length - 1}" v-for="(item, index) in crumbList" :key="index">
        <a :href="item.targetUrl" v-if="item.isLink">{{ item.name }}</a>
        <span v-else>{{ item.name }}</span>
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name: "Crumbs",
  props: {
    crumbList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {

    }
  }
}
</script>

<style scoped lang="scss">
  .crumb-container {
    //width: 1400px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .el-breadcrumb {
      font-size: 14px;
      .el-breadcrumb__item {
        &.isCurrentPage {
          .el-breadcrumb__inner,
          .el-breadcrumb__inner a,
          .el-breadcrumb__inner a:hover,
          .el-breadcrumb__inner:hover {
            color: #2A86FC;
            span {
              color: #2A86FC;
            }
          }
        }
      }
      .el-breadcrumb__inner a, .el-breadcrumb__inner.is-link {
        font-weight: 500;
        text-decoration: none;
      }
    }
  }

  /*@media screen and (max-width: 1580px) and (min-width: 1024px) {
    .crumb-container {
      height: 60px;
      .el-breadcrumb {
        font-size: 14px;
      }
    }
  }*/
</style>
