import Vue from 'vue';
import App from './App.vue';
import VueRouter from "vue-router";
import router from './router';
import store from './store';

import 'swiper/css/swiper.min.css';
import '@/assets/styles/quill/quill.core.css'
import '@/assets/styles/quill/quill.snow.css'
import '@/assets/styles/quill/quill.bubble.css'

//全局组件
import Element from 'element-ui'
import Header from '@/components/Header/index'
import Footer from '@/components/Footer'
import Crumbs from '@/components/Crumbs'

import Pagination from '@/components/Pagination'

Vue.use(Element)
Vue.component(Header.name, Header)
Vue.component(Footer.name, Footer)
Vue.component(Pagination.name, Pagination)
Vue.component(Crumbs.name, Crumbs)


//ElementUI注册组件的时候，还有一种写法，挂在原型上
// Vue.prototype.$msgbox = MessageBox;
// Vue.prototype.$alert = MessageBox.alert;

// import 'swiper/css/swiper.min.css';
//统一引入
import './assets/iconfont/iconfont.css';
import * as API from '@/api';
import VueLazyload from "vue-lazyload";
import atm from '@/assets/lazy.gif';

//引入自定义插件
import myPlugin from "@/plugins/myPlugin";

Vue.config.productionTip = false
Vue.use(VueRouter);
Vue.use(VueLazyload, {
  //懒加载默认的图片
  loading:atm,
});

Vue.filter('date',(str)=>{
  if(str){
    return str.substring(0,10)
  }else{
    return "";
  }
})

//引入表单校验插件
import "@/plugins/validate";

new Vue({
  render: h => h(App),
  router,
  store,
  beforeCreate() {
    Vue.prototype.$bus = this;
    Vue.prototype.$API = API;
  }
}).$mount('#app')
