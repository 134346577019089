<template>
  <!-- 底部 -->
  <div class="footer">
<!--    <img src="@/assets/images/footer/qrcode.png" class="qrcode">-->
    <div class="detail">
      <p class="desc">地址：渝北区云杉南路6号B区2期B6栋6楼</p>
      <p class="desc">重庆市工业和信息化发展中心(重庆国际电子商务交易认证中心、智博会秘书处)版权所有</p>
      <div class="bottom">
        <div class="item">
          <img src="@/assets/images/footer/gonganwb.png"/>
          <span>渝公网安备 50011202500655号</span>
        </div>
        <div class="item">
          <span><a href="https://beian.miit.gov.cn/" target="_blank" style="color: #ffffff">渝ICP备2020010679号-3</a></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped lang="scss">
.footer {
  padding: 1rem 0;
  width: 100vw;
  background: url("@/assets/images/footer/bg.png") center/cover no-repeat;
  font-size: 1.7em;
  color: #F2F9FF;
  display: flex;
  align-items: center;
  justify-content: center;
  .qrcode {
    width: 6.6em;
    height: 6.6em;
    margin-right: 3em;
  }
  .detail {
    display: flex;
    font-size: 16px;
    flex-direction: column;
    .desc {
      margin-bottom: 1.5em;
    }
    .bottom {
      display: flex;
      align-items: center;
      .item {
        display: flex;
        align-items: center;
        img {
          margin-right: 4px;
        }
        &:nth-child(1) {
          margin-right: 7em;
        }
      }
    }
  }
}/*

@media screen and (max-width: 1580px) and (min-width: 1110px) {
  .footer {
    padding: 1rem 0;
    font-size: 18px;
    color: #F2F9FF;
    display: flex;
    align-items: center;
    justify-content: center;
    .qrcode {
      width: 112px;
      height: 112px;
      margin-right: 40px;
    }
    .detail {
      width: 700px;
      display: flex;
      flex-direction: column;
      .desc {
        margin-bottom: 20px;
      }
      .bottom {
        display: flex;
        align-items: center;
        .item {
          display: flex;
          align-items: center;
          img {
            margin-right: 4px;
          }
          &:nth-child(1) {
            margin-right: 100px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1100px) and (min-width: 480px) {
  .footer {
    font-size: 10px;
    .qrcode {
      width: 50px;
      height: 50px;
      margin-right: 20px;
    }
    .detail {
      width: 35em;
      font-size: .8em;
      .desc {
        margin-bottom: 1em;
      }
      .bottom {
        .item {
          &:nth-child(1) {
            margin-right: 2em;
          }
        }
      }
    }
  }
}*/
</style>
