// import Search from "@/pages/Search";
/*import Login from "@/pages/Login";
import Register from "@/pages/Register";*/

/*const home = () => {
    return import('@/pages/Home');
}*/



export default [
    {
        path: '/index',
        component: () => import('@/pages/Index'), //懒加载，更加高效
        name: 'index',
        meta: {show: true, name: '首页'}
    },
    {
        path: '/news',
        component: () => import('@/pages/News'), //懒加载，更加高效
        name: 'news',
        meta: {navIndex: 1, name: '信息发布', children: [
                {id: 1, name: '要闻', icon: 'icon-shiyongwendang'},
                {id: 2, name: '通知公告', icon: 'icon-tongzhi'},
                {id: 3, name: '政策规章', icon: 'icon-a-114-guizhang'},
                {id: 4, name: '经验介绍', icon: 'icon-jingyan'},
        ]},
    },
    {
        path: '/newsDetail',
        component: () => import('@/pages/News/NewsDetail'),
        name: 'newsDetail'
    },
    {
        path: '/activity',
        component: () => import('@/pages/Activity'),
        name: 'activity'
    },
    {
        path: '/oauth2',
        component: () => import('@/pages/Index/oauth2'),
        name: 'oauth2'
    },
    {
        path: '/activityDetail',
        component: () => import('@/pages/Activity/Detail'),
        name: 'detail'
    },
    {
        path: '/teachingActivityDetail',
        component: () => import('@/pages/ResearchActivities/Detail'),
        name: 'detail'
    },
    /*
    * 匡
    * */
    {
        path: '/Member',
        component: () => import('@/pages/Member'), //成员单位
        name: 'Member',
        meta: {show: true}
    },
    {
        path: '/MemberUnitsDetails/:id',
        component: () => import('@/pages/MemberUnitsDetails'), //成员单位详情
        name: 'MemberUnitsDetails',
        meta: {show: true}
    },
    {
        path: '/MemberUnitsDetails/TeachTeam/:id',
        component: () => import('@/pages/MemberUnitsDetails/TeachTeam'), //成员单位详情
        name: 'TeachTeam',
        meta: {show: true}
    },
    {
        path: '/MemberUnitCourse',
        component: () => import('@/pages/MemberUnitCourse'), //成员单位课程
        name: 'MemberUnitCourse',
        meta: {show: true}
    },
    {
        path: '/TeachingActivities',
        component: () => import('@/pages/TeachingActivities'), //教学活动
        name: 'TeachingActivities',
        meta: {show: true}
    },
    {
        path: '/ResearchActivities',
        component: () => import('@/pages/ResearchActivities'), //教研活动
        name: 'ResearchActivities',
        meta: {show: true}
    },
    {
        path: '/planning-detail',
        component: () => import('@/pages/details/planning'), //教学计划详情
        name: 'planning-detail',
        meta: {show: true}
    },
    {
        path: '/TeacherExperts',
        component: () => import('@/pages/TeacherExperts'), //教学专家
        name: 'TeacherExperts',
        meta: {show: true}
    },
    {
        path: '/TeacherDetails',
        component: () => import('@/pages/TeacherDetails'), //教师详情
        name: 'TeacherDetails',
        meta: {show: true}
    },
	{
        path: '/textbook-detail',
        component: () => import('@/pages/details/textbook'), //教材详情
        name: 'textbook-detail',
        meta: {show: true}
	},
	{path: '/authentication',
        component: () => import('@/pages/authentication/index'), //考试认证
        name: 'authentication',
        meta: {show: true}
	},
	{path: '/authentication-detail',
        component: () => import('@/pages/authentication/detail'), //考试认证详情
       name: 'authentication-detail',
        meta: {show: true}
	},
	{
 path: '/course',
component: () => import('@/pages/course/index'), //开发课程
name: 'course',
meta: {show: true}
	},
    {
        path: '/project',
        component: () => import('@/pages/project/index'), //实训项目
        name: 'project',
        meta: {show: true}
    },
    {
        path: '/projectDetail',
        component: () => import('@/pages/projectDetail/index'), //实训详情
        name: 'projectDetail',
        meta: {show: true}
    },
	{
path: '/course-detail',
component: () => import('@/pages/course/detail'), //课程详情
name: 'course-detail',
meta: {show: true}
	},
	{
path: '/aboutus',
component: () => import('@/pages/aboutus/index'), //关于我们
name: 'aboutus',
meta: {show: true}
	},
    //重定向
    {
        path: '*',
        redirect: '/index'
    },
    {
        path: '/searchResult',
        component: () => import('@/pages/SearchResult/index'),
        name : 'searchResult',
        meta :{show:true}
    },
    {
        path: '/myRegistration',
        component: () => import('@/pages/myRegistration/index'),
        name : 'myRegistration',
        meta :{show:true}
    }
]
