//Vue插件一定暴露一个对象
let myPlugin = {};

myPlugin.install = function (Vue, optionalObj) {
    console.log('调用');
    Vue.directive(optionalObj.name, (element,params) => {
        console.log('执行',element,params);
        element.innerHTML = params.value.toUpperCase();
    });

}

export default myPlugin;
