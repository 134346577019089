//API进行统一管理
import requests from "@/api/request";

//axios请求后返回的是Promise
export const reqCategoryList = () => requests({ url: '/product/getBaseCategoryList', method: 'get' });

//获取搜索模块数据  地址：/api/list  请求方式：post  参数：需要带参数
export const reqSearchList = (params = {}) => requests({ url: '/list', method: 'post', data: params });

//获取商品详情信息
export const reqGoodInfo = (skuId) => requests({ url: `/item/${skuId}`, method: 'get' });

//获取我的订单列表
export const reqOrderList = (page, limit) => requests({url: `/order/auth/${page}/${limit}`, method: 'get'});
