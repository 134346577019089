import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
import news from './news'
import user from './user';
/*import home from './home';
import search from './search';
import detail from './detail';
import shopcart from './shopcart';*/
// import trade from './trade';

export default new Vuex.Store({
    modules: {
        user,
        news,
    }
})

