/*
* 新闻列表页接口
* */
import requests from "@/api/request";

export const reqNewsTypeList = () => requests({ url: '/basedata/open/sfNews/getCarouselNewsList', method: 'get'})

//新闻列表
export const reqNewsList = (params, newsType) => requests({ url: `/basedata/open/sfNews/getCarouselNewsList?category=${newsType}`, method: 'get', data: params });

//根据新闻类型获取列表
export const reqNewsListByType = (params) => requests({ url: '/basedata/open/sfNews/getCarouselNewsList', method: 'get', data: params });
